import axios from 'axios';

const instance = axios.create({
  //baseURL: 'https://api.uzerpass.com.br/api',
  baseURL: 'https://suporte-api.uzerpass.com.br/api',  
  ////baseURL: 'http://localhost/BUzer.Acesso/api',  
  ////baseURL: 'https://luxsolucoes.com.br/BUzer-Acesso/api',
  //baseURL: 'https://localhost:44339/api'  
});

export default instance;