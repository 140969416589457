// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

//Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_TOKEN_SET = 'user_token_set';

// Chat Module const

export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';

// Padrao Fluxo
export const APLICA_FILTRO = 'aplica_filtro';
export const APLICA_FILTRO_COMPANY = 'aplica_filtro_company';
export const APLICA_FILTRO_CLIENTE_ALTERNAR = 'aplica_filtro_cliente_alternar';
export const ALTERNA_CLIENTE = 'alterna_cliente';
export const ALTERNA_CLIENTE_SUCESSO = 'alterna_cliente_sucesso';
export const FECHA_DRAWER_ALTERNACAO = 'fecha_drawer_alternacao';
export const ABORTA_OPERACAO = 'aborta_operacao';
export const CONSULTA_CPF = 'consulta_cpf';
export const CONSULTA_CPF_SUCESSO = 'consulta_cpf_sucesso';
export const CANCELA_EDICAO = 'cancela_edicao';
export const ON_SHOW_ERROR_MESSAGE = 'on_show_error_message';
export const ALTERACAO_CAMPO = 'alteracao_campo';
export const ALTERACAO_COMBO = 'alteracao_combo';
export const ALTERACAO_COMBO_INICIO = 'alteracao_combo_inicio';
export const ALTERACAO_DATEPICKER = 'alteracao_datepicker';
export const CLIQUE_CONTROLE_ABA_LOCAL = 'clique_controle_aba_local';
export const ALTERACAO_CHECK = 'alteracao_check';
export const ALTERACAO_CHECK_USUARIO = 'alteracao_check_usuario';
export const ALTERACAO_CAMPO_MASCARA_CPF = 'alteracao_campo_mascara_cpf';
export const ALTERACAO_CAMPO_MASCARA_CNPJ = 'alteracao_campo_mascara_cnpj';
export const ALTERACAO_CAMPO_MASCARA_TELEFONE = 'alteracao_campo_mascara_telefone';
export const ALTERACAO_CAMPO_MASCARA_IP = 'alteracao_campo_mascara_ip'; 
export const ALTERACAO_CAMPO_MASCARA_CEP = 'alteracao_campo_mascara_cep';
export const ALTERACAO_CAMPO_MASCARA_DATE = 'alteracao_campo_mascara_date';
export const ALTERACAO_CHECK_PONTO_ACESSO = 'alteracao_check_ponto_acesso';
export const SALVAR_CLIENTE_EM_USO = 'salvar_cliente_em_uso';
export const ALTERAR_CLIENTE_EM_USO = 'alterar_cliente_em_uso';
export const INICIA_DEFINICOES = 'inicia_definicoes';
export const INICIO_DEFINICOES_SUCESSO = 'inicio_definicoes_sucesso';
export const INICIA_RELATORIO_ACESSO = 'inicia_relatorio_acesso';
export const INICIO_RELATORIO_ACESSO_SUCESSO = 'inicio_relatorio_acesso_sucesso';
export const INICIA_RELATORIO_PESSOAS_UNIDADE = 'inicia_relatorio_pessoas_unidade';
export const INICIA_RELATORIO_PESSOAS_UNIDADE_SUCESSO = 'inicia_relatorio_pessoas_unidade_sucesso';
export const GERA_RELATORIO_ACESSO = 'gera_relatorio_acesso';
export const GERA_RELATORIO_ACESSO_SUCESSO = 'gera_relatorio_acesso_sucesso';
export const GERA_RELATORIO_PESSOAS_UNIDADE = 'gera_relatorio_pessoas_unidade';
export const GERA_RELATORIO_PESSOAS_UNIDADEO_SUCESSO = 'gera_relatorio_pessoas_unidade_sucesso';
export const TOKEN_INVALIDO = 'token_invalido';
export const VALIDACAO_CAMPO = 'validacao_campo';
export const LIMPA_VALIDACOES = 'limpa_validacoes';
export const FECHA_MODULOS = 'fecha_modulos';
export const SELECIONA_MODULOS = 'seleciona_modulos';
export const INICIA_CARREGAMENTO_CONFIGURACAO = 'inicia_carregamento_configuracao';
export const ATIVA_TESTES = 'ativa_testes';
export const DESATIVA_TIMER = 'desativa_timer';
export const ATUALIZA_DATA_PESQUISA_LIBERACAO = 'atualiza_data_pesquisa_liberacao';
export const LIMPA_PESQUISA_LIBERACAO = 'limpa_pesquisa_liberacao';
export const ALTERACAO_CAMPO_PESQUISA_PESSOA_TESTE = 'alteracao_campo_pesquisa_pessoa_teste';
export const ALTERACAO_CAMPO_PESQUISA_UNIDADE_GARAGEM = 'alteracao_campo_pesquisa_unidade_garagem';
export const APLICAR_FILTRO_SUCESSO = 'aplicar_filtro_sucesso';

// Modelos de equipamentos
export const INICIA_MODELO = 'inicia_modelo';
export const SALVA_MODELO = 'salva_modelo';
export const REMOVE_MODELO = 'remove_modelo';
export const NOVO_MODELO = 'novo_modelo';
export const BUSCA_MODELO_EDICAO = 'busca_modelo_edicao';
export const INICIO_MODELO_SUCESSO = 'inicio_modelo_sucesso';
export const SALVA_MODELO_SUCESSO = 'salva_modelo_success';
export const REMOVE_MODELO_SUCESSO = 'remove_modelo_success';
export const BUSCA_MODELO_EDICAO_SUCESSO = 'busca_modelo_edicao_sucesso';
export const LISTA_MODELO_SUCESSO = 'lista_modelo_sucesso';
export const LIMPAR_MODELO = 'limpar_modelo';
export const LISTA_MODELO = 'lista_modelo';

// Equipamentos
export const INICIA_EQUIPAMENTO = 'inicia_equipamento';
export const SALVA_EQUIPAMENTO = 'salva_equipamento';
export const REMOVE_EQUIPAMENTO = 'remove_equipamento';
export const NOVO_EQUIPAMENTO = 'novo_equipamento';
export const BUSCA_EQUIPAMENTO_EDICAO = 'busca_equipamento_edicao';
export const INICIO_EQUIPAMENTO_SUCESSO = 'inicio_equipamento_sucesso';
export const SALVA_EQUIPAMENTO_SUCESSO = 'salva_equipamento_success';
export const REMOVE_EQUIPAMENTO_SUCESSO = 'remove_equipamento_success';
export const BUSCA_EQUIPAMENTO_EDICAO_SUCESSO = 'busca_equipamento_edicao_sucesso';
export const LISTA_EQUIPAMENTO_SUCESSO = 'lista_equipamento_sucesso';
export const LIMPAR_EQUIPAMENTO = 'limpar_equipamento';
export const LISTA_EQUIPAMENTO = 'lista_equipamento';

// Unidades
export const INICIA_UNIDADE = 'inicia_unidade';
export const SALVA_UNIDADE = 'salva_unidade';
export const REMOVE_UNIDADE = 'remove_unidade';
export const NOVO_UNIDADE = 'novo_unidade';
export const BUSCA_UNIDADE_EDICAO = 'busca_unidade_edicao';
export const INICIO_UNIDADE_SUCESSO = 'inicio_unidade_sucesso';
export const SALVA_UNIDADE_SUCESSO = 'salva_unidade_success';
export const REMOVE_UNIDADE_SUCESSO = 'remove_unidade_success';
export const BUSCA_UNIDADE_EDICAO_SUCESSO = 'busca_unidade_edicao_sucesso';
export const LISTA_UNIDADE_SUCESSO = 'lista_unidade_sucesso';
export const LIMPAR_UNIDADE = 'limpar_unidade';
export const LISTA_UNIDADE = 'lista_unidade';
export const INICIO_CENARIO_UNIDADE_LOCAL = 'inicio_cenario_unidade_local';
export const INICIO_CENARIO_UNIDADE_LOCAL_SUCESSO = 'inicio_cenario_unidade_sucesso';
export const NOVO_ESCANINHO = 'novo_escaninho';
export const ATUALIZA_ESCANINHO = 'atualiza_escaninho';
export const REMOVE_ESCANINHO = 'remove_escaninho';
export const NOVA_GARAGEM = 'nova_garagem';
export const ATUALIZA_GARAGEM = 'atualiza_garagem';
export const REMOVE_GARAGEM = 'remove_garagem';

// Categoria
export const INICIA_CATEGORIA = 'inicia_categoria';
export const SALVA_CATEGORIA = 'salva_categoria';
export const REMOVE_CATEGORIA = 'remove_categoria';
export const NOVO_CATEGORIA = 'novo_categoria';
export const BUSCA_CATEGORIA_EDICAO = 'busca_categoria_edicao';
export const INICIO_CATEGORIA_SUCESSO = 'inicio_categoria_sucesso';
export const SALVA_CATEGORIA_SUCESSO = 'salva_categoria_success';
export const REMOVE_CATEGORIA_SUCESSO = 'remove_categoria_success';
export const BUSCA_CATEGORIA_EDICAO_SUCESSO = 'busca_categoria_edicao_sucesso';
export const LISTA_CATEGORIA_SUCESSO = 'lista_categoria_sucesso';
export const LIMPAR_CATEGORIA = 'limpar_categoria';
export const LISTA_CATEGORIA = 'lista_categoria';
export const INICIO_CENARIO_CATEGORIA_LOCAL = 'inicio_cenario_categoria_local';
export const INICIO_CENARIO_CATEGORIA_LOCAL_SUCESSO = 'inicio_cenario_categoria_local_sucesso';

// Ambientes
export const INICIA_AMBIENTE = 'inicia_ambiente';
export const INICIA_GESTAO_ACESSO = 'inicia_gestao_acesso';
export const INICIO_AMBIENTE_SUCESSO = 'inicio_ambiente_sucesso';
export const INICIO_CENARIO_AMBIENTE_LOCAL = 'inicio_cenario_ambiente_local';
export const INICIO_CENARIO_AMBIENTE_LOCAL_SUCESSO = 'inicio_cenario_ambiente_local_sucesso';
export const CLIQUE_BOTAO_TREEVIEW = 'clique_botao_treeview';
export const ADICIONA_PRIMEIRO_AMBIENTE_LOCAL = 'adiciona_primeiro_ambiente_local';
export const CLIQUE_ADICIONA_AMBIENTE_LOCAL = 'clique_adiciona_ambiente_local';
export const CLIQUE_ADICIONA_PONTO_ACESSO = 'clique_adiciona_ponto_acesso';
export const CLIQUE_ADICIONA_SUB_AMBIENTE = 'clique_adiciona_sub_ambiente';
export const ADICIONA_AMBIENTE_LOCAL = 'adiciona_ambiente_local';
export const ADICIONA_PONTO_ACESSO = 'adiciona_ponto_acesso';
export const ADICIONA_SUB_AMBIENTE = 'adiciona_sub_ambiente';
export const SALVA_AMBIENTES = 'salva_ambientes';
export const SALVA_AMBIENTES_SUCESSO = 'salva_ambientes_sucesso';
export const SALVA_GESTAO_ACESSO = 'salva_gestao_Acesso';
export const SALVA_GESTAO_ACESSO_SUCESSO = 'salva_gestao_acesso_sucesso';
export const PREENCHE_INFORMACAO_PONTO = 'preenche_informacao_ponto';
export const REMOVE_PONTO_ACESSO_LOCAL = 'remove_ponto_acesso_local';
export const REMOVE_AMBIENTE = 'remove_ambiente';
export const ATUALIZA_INFORMACAO_PONTO = 'atualiza_informacao_ponto';
export const CANCELA_INFORMACAO_PONTO = 'cancela_informacao_ponto';

// Titularidades
export const INICIA_TITULARIDADE = 'inicia_titularidade';
export const SALVA_TITULARIDADE = 'salva_titularidade';
export const REMOVE_TITULARIDADE = 'remove_titularidade';
export const NOVO_TITULARIDADE = 'novo_titularidade';
export const BUSCA_TITULARIDADE_EDICAO = 'busca_titularidade_edicao';
export const INICIO_TITULARIDADE_SUCESSO = 'inicio_titularidade_sucesso';
export const SALVA_TITULARIDADE_SUCESSO = 'salva_titularidade_success';
export const REMOVE_TITULARIDADE_SUCESSO = 'remove_titularidade_success';
export const BUSCA_TITULARIDADE_EDICAO_SUCESSO = 'busca_titularidade_edicao_sucesso';
export const LISTA_TITULARIDADE_SUCESSO = 'lista_titularidade_sucesso';
export const LIMPAR_TITULARIDADE = 'limpar_titularidade';
export const LISTA_TITULARIDADE = 'lista_titularidade';
export const INICIO_CENARIO_TITULARIDADE_LOCAL = 'inicio_cenario_titularidade_local';
export const INICIO_CENARIO_TITULARIDADE_LOCAL_SUCESSO = 'inicio_cenario_titularidade_sucesso';

// Clientes
export const INICIA_CLIENTE = 'inicia_cliente';
export const SALVA_CLIENTE = 'salva_cliente';
export const REMOVE_CLIENTE = 'remove_cliente';
export const CONSULTA_CEP = 'consulta_cep';
export const NOVO_CLIENTE = 'novo_cliente';
export const BUSCA_CLIENTE_EDICAO = 'busca_cliente_edicao';
export const INICIO_CLIENTE_SUCESSO = 'inicio_cliente_sucesso';
export const SALVA_CLIENTE_SUCESSO = 'salva_cliente_success';
export const REMOVE_CLIENTE_SUCESSO = 'remove_cliente_success';
export const BUSCA_CLIENTE_EDICAO_SUCESSO = 'busca_cliente_edicao_sucesso';
export const CONSULTA_CEP_SUCESSO = 'consulta_cep_sucesso';
export const CONSULTA_CEP_ERRO = 'consulta_cep_erro';
export const LISTA_CLIENTE_SUCESSO = 'lista_cliente_sucesso';
export const LIMPAR_CLIENTE = 'limpar_cliente';
export const LISTA_CLIENTE = 'lista_cliente';
export const SETAR_IMAGEM_CLIENTE = 'setar_imagem_cliente';
export const NOVO_TELEFONE = 'novo_telefone';
export const ATUALIZA_TELEFONE = 'atualiza_telefone';
export const REMOVE_TELEFONE = 'remove_telefone';
export const NOVO_EMAIL = 'novo_email';
export const ATUALIZA_EMAIL = 'atualiza_email';
export const REMOVE_EMAIL = 'remove_email';
export const CLIQUE_CONTROLE_ABA_CLIENTE = 'clique_controle_aba_local';

// Locais Controlados
export const INICIA_LOCAL_CONTROLADO = 'inicia_local_controlado';
export const SALVA_LOCAL_CONTROLADO = 'salva_local_controlado';
export const REMOVE_LOCAL_CONTROLADO = 'remove_local_controlado';
export const NOVO_LOCAL_CONTROLADO = 'novo_local_controlado';
export const BUSCA_LOCAL_CONTROLADO_EDICAO = 'busca_local_controlado_edicao';
export const INICIO_LOCAL_CONTROLADO_SUCESSO = 'inicio_local_controlado_sucesso';
export const SALVA_LOCAL_CONTROLADO_SUCESSO = 'salva_local_controlado_success';
export const REMOVE_LOCAL_CONTROLADO_SUCESSO = 'remove_local_controlado_success';
export const BUSCA_LOCAL_CONTROLADO_EDICAO_SUCESSO = 'busca_local_controlado_edicao_sucesso';
export const ADICIONA_AMBIENTE_LISTA_VINCULADA = 'adiciona_ambiente_lista_vinculada';
export const REMOVE_AMBIENTE_LISTA_VINCULADA = 'remove_ambiente_lista_vinculada';
export const LISTA_LOCAL_CONTROLADO_SUCESSO = 'lista_local_controlado_sucesso';
export const LIMPAR_LOCAL_CONTROLADO = 'limpar_local_controlado';
export const LISTA_LOCAL_CONTROLADO = 'lista_local_controlado';
export const SETAR_IMAGEM_LOCAL_CONTROLADO = 'setar_imagem_local_controlado';
export const REMOVE_RESPONSABILIDADE = 'remove_responsabilidade';
export const ADICIONA_RESPONSABILIDADE = 'adiciona_responsabilidade';
export const ATUALIZA_RESPONSABILIDADE = 'atualiza_responsabilidade';
export const ATUALIZAR_PERFIL_AUTORIZADO_LOCAL = 'atualizar_perfil_autorizado_local';

// Pontos de Acesso
export const INICIA_PONTO_ACESSO = 'inicia_ponto_acesso';
export const SALVA_PONTO_ACESSO = 'salva_ponto_acesso';
export const REMOVE_PONTO_ACESSO = 'remove_ponto_acesso';
export const LISTA_PONTO_ACESSO = 'lista_ponto_acesso';
export const NOVO_PONTO_ACESSO = 'novo_ponto_acesso';
export const BUSCA_PONTO_ACESSO_EDICAO = 'busca_ponto_acesso_edicao';
export const INICIO_PONTO_ACESSO_SUCESSO = 'inicio_ponto_acesso_sucesso';
export const SALVA_PONTO_ACESSO_SUCESSO = 'salva_ponto_acesso_success';
export const REMOVE_PONTO_ACESSO_SUCESSO = 'remove_ponto_acesso_success';
export const BUSCA_PONTO_ACESSO_EDICAO_SUCESSO = 'busca_ponto_acesso_edicao_sucesso';
export const LISTA_LOCAIS_POR_CLIENTE_PONTO_ACESSO = 'lista_locais_por_cliente_ponto_acesso';
export const LISTA_LOCAIS_SUCESSO = 'lista_locais_sucesso';
export const LISTA_AMBIENTES_POR_LOCAL = 'lista_ambientes_por_local';
export const LISTA_AMBIENTES_PONTOS_POR_LOCAL = 'lista_ambientes_pontos_por_local';
export const LISTA_AMBIENTES_SUCESSO = 'lista_ambientes_sucesso';
export const LISTA_PONTO_ACESSO_SUCESSO = 'lista_ponto_acesso_sucesso';
export const LIMPAR_PONTO_ACESSO = 'limpar_ponto_acesso';
export const LISTA_LOCAIS_SUCESSO_PONTO_ACESSO = 'lsita_locais_sucesso_ponto_acesso'; 

// Perfis de acesso físico
export const INICIA_PERFIL = 'inicia_perfil';
export const SALVA_PERFIL = 'salva_perfil';
export const REMOVE_EVENTO_PESSOA_PERFIL = "remove_evento_pessoa_perfil";
export const REMOVE_PERFIL = 'remove_perfil';
export const NOVO_PERFIL = 'novo_perfil';
export const BUSCA_PERFIL_EDICAO = 'busca_perfil_edicao';
export const INICIO_PERFIL_SUCESSO = 'inicio_perfil_sucesso';
export const SALVA_PERFIL_SUCESSO = 'salva_perfil_success';
export const REMOVE_PERFIL_SUCESSO = 'remove_perfil_success';
export const BUSCA_PERFIL_EDICAO_SUCESSO = 'busca_perfil_edicao_sucesso';
export const LISTA_PERFIL_SUCESSO = 'lista_perfil_sucesso';
export const LIMPAR_PERFIL = 'limpar_perfil';
export const LISTA_PERFIL = 'lista_perfil';
export const BUSCA_PESSOA_BASE_UZER_USUARIO_PORTAL = 'busca_pessoa_base_uzer_usuario_portal';
export const EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL = 'exibe_detalhe_estrutura_local_perfil';
export const EXIBE_ESTRUTURA_LOCAL_PERFIL = 'exibe_estrutura_local_perfil';
export const EXIBE_DETALHE_ESTRUTURA_LOCAL_PERFIL_SUCESSO = 'exibe_detalhe_estrutura_local_perfil_sucesso';
export const ATUALIZA_AMBIENTE_ORIGEM = 'atualiza_ambiente_origem';

// Perfis do Portal
export const INICIA_PERFIL_PORTAL = 'inicia_perfil_portal';
export const SALVA_PERFIL_PORTAL = 'salva_perfil_portal';
export const REMOVE_PERFIL_PORTAL = 'remove_perfil_portal';
export const NOVO_PERFIL_PORTAL = 'novo_perfil_portal';
export const BUSCA_PERFIL_PORTAL_EDICAO = 'busca_perfil_portal_edicao';
export const INICIO_PERFIL_PORTAL_SUCESSO = 'inicio_perfil_portal_sucesso';
export const SALVA_PERFIL_PORTAL_SUCESSO = 'salva_perfil_portal_success';
export const REMOVE_PERFIL_PORTAL_SUCESSO = 'remove_perfil_portal_success';
export const BUSCA_PERFIL_PORTAL_EDICAO_SUCESSO = 'busca_perfil_portal_edicao_sucesso';
export const LISTA_PERFIL_PORTAL_SUCESSO = 'lista_perfil_portal_sucesso';
export const LIMPAR_PERFIL_PORTAL = 'limpar_perfil_portal';
export const LISTA_PERFIL_PORTAL = 'lista_perfil_portal';
export const ATUALIZAR_PONTO_REQUISICAO = 'atualizar_ponto_requisicao';
export const ATUALIZAR_PERMISSAO_FUNCIONALIDADE = 'atualizar_permissao_funcionalidade';
export const SELECIONAR_TUDO_FUNCIONALIDADE_PERFIL = 'selecionar_tudo_funcionalidade_perfil';
export const SELECIONAR_TUDO_PORTARIA_PERFIL = 'selecionar_tudo_portaria_perfil';
export const ALTERACAO_CHECK_PERFIL = 'alteracao_check_perfil';

// Usuários da Plataforma (Pessoas)
export const INICIA_USUARIO_PLATAFORMA = 'inicia_usuario_plataforma';
export const SALVA_USUARIO_PLATAFORMA = 'salva_usuario_plataforma';
export const SALVA_USUARIO_PLATAFORMA_LITE = 'salva_usuario_plataforma_lite';
export const REMOVE_CLIENTE_PESSOA_PLATAFORMA = 'remove_cliente_pessoa_plataforma';
export const REMOVE_USUARIO_PLATAFORMA = 'remove_usuario_plataforma';
export const NOVO_USUARIO_PLATAFORMA = 'novo_usuario_plataforma';
export const BUSCA_USUARIO_PLATAFORMA_EDICAO = 'busca_usuario_plataforma_edicao';
export const BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO = 'busca_usuario_plataforma_lite_edicao';
export const BUSCA_PESSOA_BASE_UZER = 'busca_pessoa_base_uzer';
export const BUSCA_PESSOA_BASE_UZER_LITE = 'busca_pessoa_base_uzer_lite';
export const INICIO_USUARIO_PLATAFORMA_SUCESSO = 'inicio_usuario_plataforma_sucesso';
export const INICIO_PESSOAS_EVENTO = 'inicio_pessoas_evento';
export const INICIO_PESSOAS_EVENTO_SUCESSO = 'inicio_pessoas_evento_sucesso';
export const SALVA_USUARIO_PLATAFORMA_SUCESSO = 'salva_usuario_plataforma_success';
export const REMOVE_USUARIO_PLATAFORMA_SUCESSO = 'remove_usuario_plataforma_success';
export const REMOVE_EVENTO_PESSOA_PERFIL_SUCESSO = 'remove_evento_pessoa_perfil_success';
export const BUSCA_USUARIO_PLATAFORMA_EDICAO_SUCESSO = 'busca_usuario_plataforma_edicao_sucesso';
export const BUSCA_USUARIO_PLATAFORMA_LITE_EDICAO_SUCESSO = 'busca_usuario_plataforma_lite_edicao_sucesso';
export const LISTA_PERFIS_POR_CLIENTE = 'lista_perfis_por_cliente';
export const LISTA_PERFIS_SUCESSO = 'lista_perfis_sucesso';
export const ADICIONA_PERFIL_LISTA_VINCULADA = 'adiciona_perfil_lista_vinculada';
export const REMOVE_PERFIL_LISTA_VINCULADA = 'remove_perfil_lista_vinculada';
export const LISTA_USUARIO_PLATAFORMA_SUCESSO = 'lista_usuario_plataforma_sucesso';
export const LIMPAR_USUARIO_PLATAFORMA = 'limpar_usuario_plataforma';
export const LISTA_USUARIO_PLATAFORMA = 'lista_usuario_plataforma';
export const SETAR_IMAGEM_PESSOA = 'setar_imagem_pessoa';
export const IR_PARA_CADASTRO_PERFIL = 'ir_para_cadastro_perfil';
export const NOVO_TELEFONE_PESSOA = 'novo_telefone_pessoa';
export const NOVO_PERFIL_PESSOA_LITE = 'novo_perfil_pessoa_lite';
export const ATUALIZA_TELEFONE_PESSOA = 'atualiza_telefone_pessoa';
export const ATUALIZA_PERFIL_PESSOA_LITE = 'atualiza_perfil_pessoa_lite';
export const REMOVE_PERFIL_PESSOA_LITE = 'remove_perfil_pessoa_lite';
export const REMOVE_TELEFONE_PESSOA = 'remove_telefone_pessoa';
export const NOVO_EMAIL_PESSOA = 'novo_email_pessoa';
export const ATUALIZA_EMAIL_PESSOA = 'atualiza_email_pessoa';
export const NOVO_COMPLEMENTO = 'novo_complemento';
export const ATUALIZA_COMPLEMENTO = 'atualiza_complemento';
export const REMOVE_COMPLEMENTO = 'remove_complemento';
export const REMOVE_EMAIL_PESSOA = 'remove_email_pessoa';
export const ALTERA_STATUS_PESSOA = 'altera_status_pessoa';
export const CLIQUE_CONTROLE_ABA_USUARIO_PLATAFORMA = 'clique_controle_aba_usuario_plataforma';
export const ALTERA_STATUS_PESSOA_LOCAL = 'altera_status_pessoa_local';
export const EXIBE_ESTRUTURA_LOCAL = 'exibe_estrutura_local';
export const EXIBE_DETALHE_ESTRUTURA_LOCAL = 'exibe_detalhe_estrutura_local';
export const EXIBE_DETALHE_ESTRUTURA_LOCAL_SUCESSO = 'exibe_detalhe_estrutura_local_sucesso';
export const ADICIONA_VINCULO_PESSOA = 'adiciona_vinculo_pessoa';
export const REMOVER_VINCULO_PESSOA = 'remover_vinculo_pessoa';
export const ATUALIZAR_VINCULO_PESSOA = 'atualizar_vinculo_pessoa';
export const ATUALIZAR_AMBIENTE_PESSOA = 'atualizar_ambiente_pessoa';
export const ATIVA_CAPTURA_IMAGEM = 'ativa_captura_imagem';
export const CANCELA_CAPTURA_IMAGEM = 'cancela_captura_imagem';
export const OBTEM_IMAGEM_ARRAY_PESSOAS = 'obtem_imagem_array_pessoas';
export const OBTEM_IMAGEM_ARRAY_PESSOAS_SUCESSO = 'obtem_imagem_array_pessoas_sucesso';
export const ENVIAR_SMS_PESSOA_EVENTO = 'enviar_sms_pessoa_evento';
export const ENVIAR_SMS_PESSOA_EVENTO_SUCESSO = 'enviar_sms_pessoa_evento_sucesso';
// Usuários do Portal
export const INICIA_USUARIO_PORTAL = 'inicia_usuario_portal';
export const SALVA_USUARIO_PORTAL = 'salva_usuario_portal';
export const REMOVE_USUARIO_PORTAL = 'remove_usuario_portal';
export const NOVO_USUARIO_PORTAL = 'novo_usuario_portal';
export const BUSCA_USUARIO_PORTAL_EDICAO = 'busca_usuario_portal_edicao';
export const INICIO_USUARIO_PORTAL_SUCESSO = 'inicio_usuario_portal_sucesso';
export const SALVA_USUARIO_PORTAL_SUCESSO = 'salva_usuario_portal_success';
export const REMOVE_USUARIO_PORTAL_SUCESSO = 'remove_usuario_portal_success';
export const BUSCA_USUARIO_PORTAL_EDICAO_SUCESSO = 'busca_usuario_portal_edicao_sucesso';
export const ADICIONA_PERFIL_PORTAL_LISTA_VINCULADA = 'adiciona_perfil_portal_lista_vinculada';
export const REMOVE_PERFIL_PORTAL_LISTA_VINCULADA = 'remove_perfil_portal_lista_vinculada';
export const LISTA_USUARIO_PORTAL_SUCESSO = 'lista_usuario_portal_sucesso';
export const LIMPAR_USUARIO_PORTAL = 'limpar_usuario_portal';
export const LISTA_USUARIO_PORTAL = 'lista_usuario_portal';
export const SETAR_IMAGEM_PESSOA_USUARIO = 'setar_imagem_pessoa_usuario';
export const ADICIONA_CLIENTE_LISTA_VINCULADA = 'adiciona_cliente_lista_vinculada';
export const REMOVE_CLIENTE_LISTA_VINCULADA = 'remove_cliente_lista_vinculada';
export const EXIBE_PONTOS_REQUISICAO = 'exibe_pontos_requisicao';
export const ATUALIZAR_PONTO_REQUISICAO_USUARIO = 'atualizar_ponto_requisicao_usuario';
export const ATUALIZAR_PERMISSAO_FUNCIONALIDADE_USUARIO = 'atualizar_permissao_funcionalidade_usuario';
export const NOVO_TELEFONE_USUARIO_PORTAL = 'novo_telefone_usuario_portal';
export const ATUALIZA_TELEFONE_USUARIO_PORTAL = 'atualiza_telefone_usuario_portal';
export const REMOVE_TELEFONE_USUARIO_PORTAL = 'remove_telefone_usuario_portal';
export const NOVO_EMAIL_USUARIO_PORTAL = 'novo_email_usuario_portal';
export const ATUALIZA_EMAIL_USUARIO_PORTAL = 'atualiza_email_usuario_portal';
export const REMOVE_EMAIL_USUARIO_PORTAL = 'remove_email_usuario_portal';
export const SETAR_IMAGEM_USUARO = 'setar_imagem_usuario';
export const SELECIONAR_TUDO_FUNCIONALIDADE = 'selecionar_tudo_funcionalidade';
export const SELECIONAR_TUDO_PORTARIA = 'selecionar_tudo_portaria';
export const CLIQUE_CONTROLE_ABA_USUARIO_PORTAL = 'clique_controle_aba_usuario_portal';

// Portaria
export const EXIBE_PONTOS_SELECAO = 'exibe_pontos_selecao';
export const EXIBE_TELA_SELECAO_MODO = 'exibe_tela_selecao_modo';
export const CONFIRMAR_SELECAO_PONTOS = 'confirmar_selecao_pontos';
export const ADICIONA_OBSERVACAO_PESSOA_PERFIL = 'adiciona_observacao_pessoa_perfil';
export const ADICIONA_OBSERVACAO_PESSOA_PERFIL_SUCESSO = 'adiciona_observacao_pessoa_perfil_sucesso';
export const ATIVA_DESATIVA_BUSCA_NOTIFICACOES = 'ativa_desativa_busca_notificacoes';
export const INICIA_PORTARIA = 'inicia_portaria';
export const INICIA_PORTARIA_LIBERACAO = 'inicia_portaria_liberacao';
export const INICIA_PORTARIA_LIBERACAO_SUCESSO = 'inicia_portaria_liberacao_sucesso';
export const CARREGA_PESSOAS_PARA_TESTES = 'carrega_pessoas_para_testes';
export const CARREGA_PESSOAS_PARA_TESTES_SUCESSO = 'carrega_pessoas_para_testes_sucesso';
export const SETAR_ID_INTERVAL = 'setar_id_interval';
export const DESOCUPAR_VAGA_GARAGEM = 'desocupar_vaga_garagem';
export const APLICA_ALTERACOES_GARAGENS = 'aplica_alteracoes_garagens';
export const APLICA_ALTERACOES_GARAGENS_SUCESSO = 'aplica_alteracoes_garagens_sucesso';
export const EXIBE_LOTACAO_GARAGENS = 'exibe_lotacao_garagens';
export const EXIBE_LOTACAO_GARAGENS_SUCESSO = 'exibe_lotacao_garagens_sucesso';
export const SELECIONA_PESSOA_SIMULACAO = 'seleciona_pessoa_simulacao';
export const SELECIONA_PESSOA_PORTARIA = 'seleciona_pessoa_portaria';
export const SELECIONA_PESSOA_PORTARIA_SUCESSO = 'seleciona_pessoa_portaria_sucesso';
export const SELECIONA_LIBERACAO_PORTARIA = 'seleciona_liberacao_portaria';
export const EXECUTA_SIMULACAO = 'executa_simulacao';
export const EXECUTA_SIMULACAO_SUCESSO = 'executa_simulacao_sucesso';
export const DECIDIR_ATENDTO_DUPLO_CHECK = 'decidir_atendto_duplo_check';
export const DECIDIR_ATENDTO_DUPLO_CHECK_SUCESSO = 'decidir_atendto_duplo_check_sucesso';
export const ANALISAR_DETALHES_DC = 'analisar_detalhes_dc';
export const SETAR_IMAGEM_LIBERACAO = 'setar_imagem_liberacao';
export const SALVAR_LIBERACAO = 'salvar_liberacao';
export const SINCRONIZAR_LIBERACAO = 'sincronizar_liberacao';
export const SINCRONIZACAO_PESSOA_ERRO = 'sincronizacao_pessoa_erro';
export const CANCELA_CAPTURA_IMAGEM_PORTARIA = 'cancela_captura_imagem_portaria';
export const OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA = 'obtem_imagem_array_pessoas_portaria';
export const OBTEM_IMAGEM_ARRAY_PESSOAS_PORTARIA_SUCESSO = 'obtem_imagem_array_pessoas_portaria_sucesso';
export const ENVIA_SOLICITACAO_ABERTURA_TERMINAL = 'envia_solicitacao_abertura_terminal';
export const ENVIA_SOLICITACAO_ABERTURA_TERMINAL_SUCESSO = 'envia_solicitacao_abertura_terminal_sucesso';
export const ENVIA_SOLICITACAO_ABERTURA_TERMINAL_ERRO = 'envia_solicitacao_abertura_terminal_erro';
export const EXIBE_OCULTA_OBSERVACOES_PERFIL_PESSOA = 'exibe_oculta_observacoes_perfil_pessoa';
export const NOVA_LIBERACAO = 'nova_liberacao';
export const VOLTAR_INICIO_PORTARIA = 'voltar_inicio_portaria';
export const BUSCA_PESSOA_CADASTRADA_LIBERACAO = 'busca_pessoa_cadastrada_liberacao';
export const BUSCA_PESSOA_CADASTRADA_LIBERACAO_SUCESSO = 'busca_pessoa_cadastrada_liberacao_sucesso';
export const FECHAR_PESSOA_EXISTENTE_LIBERACAO = 'fechar_pessoa_existente_liberacao';
export const ALTERA_PERIODO_VALIDADE_LIBERACAO = 'altera_periodo_validade_liberacao';
export const ALTERA_PERIODO_DIA_SEMANA_LIBERACAO = 'altera_periodo_dia_semana_liberacao';
export const ALTERA_DIA_SEMANA_GERAL = 'altera_dia_semana_geral';
export const ALTERA_PERIODO_HORARIO_LIBERACAO = 'altera_periodo_horario_liberacao';
export const ATUALIZA_HORARIO_LIBERACAO = 'atualiza_horario_liberacao';
export const SALVA_LIBERACAO_SUCESSO = 'salva_liberacao_sucesso';
export const ALTERA_VALOR_CHECK_LIBERACAO = 'altera_valor_check_liberacao';
export const EXIBE_MENSAGEM_ERRO_PORTARIA = 'exibe_mensagem_erro_portaria';
export const OCULTA_MENSAGEM_PORTARIA = 'oculta_mensagem_portaria';
export const CAPTURA_IMAGEM_LIBERACAO = 'captura_imagem_liberacao';
export const BUSCA_SOLICITACAO = 'busca_solicitacao';
export const BUSCA_SOLICITACAO_SUCESSO = 'busca_solicitacao_sucesso';
export const BUSCA_FILA_POSICAO = 'busca_fila_posicao';
export const BUSCA_FILA_POSICAO_SUCESSO = 'busca_fila_posicao_sucesso';
export const ATUALIZAR_PONTO_REQUISICAO_PORTEIRO = 'atualizar_ponto_requisicao_porteiro';
export const ATUALIZA_MODO_PORTARIA = 'atualiza_modo_portaria';
export const ATUALIZA_MODO_PORTARIA_SUCESSO = 'atualiza_modo_portaria_sucesso';

// Concessão de Acessos (Pessoas)
export const INICIA_ACESSO_PESSOA = 'inicia_acesso_pessoa';
export const SALVA_ACESSO_PESSOA = 'salva_acesso_pessoa';
export const REMOVE_ACESSO_PESSOA = 'remove_acesso_pessoa';
export const NOVO_ACESSO_PESSOA = 'novo_acesso_pessoa';
export const BUSCA_ACESSO_PESSOA_EDICAO = 'busca_acesso_pessoa_edicao';
export const INICIO_ACESSO_PESSOA_SUCESSO = 'inicio_acesso_pessoa_sucesso';
export const SALVA_ACESSO_PESSOA_SUCESSO = 'salva_acesso_pessoa_success';
export const REMOVE_ACESSO_PESSOA_SUCESSO = 'remove_acesso_pessoa_success';
export const BUSCA_ACESSO_PESSOA_EDICAO_SUCESSO = 'busca_acesso_pessoa_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_PESSOA = 'lista_configuracao_ponto_pessoa';
export const LISTA_CONFIGURACAO_PONTO_PESSOA_SUCESSO = 'lista_configuracao_ponto_pessoa_sucesso';
export const ALTERACAO_CHECK_PONTO_ACESSO_PESSOA = 'alteracao_check_ponto_acesso_pessoa';
export const LISTA_ACESSO_PESSOA_SUCESSO = 'lista_acesso_pessoa_sucesso';
export const LIMPAR_ACESSO_PESSOA = 'limpar_acesso_pessoa';
export const LISTA_ACESSO_PESSOA = 'lista_acesso_pessoa';
export const LISTA_LOCAIS_POR_CLIENTE_ACESSO_PESSOA = 'lista_locais_por_cliente_acesso_pessoa';
export const LISTA_LOCAIS_SUCESSO_ACESSO_PESSOA = 'lista_locais_sucesso_acesso_pessoa';

// Concessão de Acessos (Acesso Full)
export const INICIA_ACESSO_FULL = 'inicia_acesso_full';
export const SALVA_ACESSO_FULL = 'salva_acesso_full';
export const REMOVE_ACESSO_FULL = 'remove_acesso_full';
export const NOVO_ACESSO_FULL = 'novo_acesso_full';
export const BUSCA_ACESSO_FULL_EDICAO = 'busca_acesso_full_edicao';
export const INICIO_ACESSO_FULL_SUCESSO = 'inicio_acesso_full_sucesso';
export const SALVA_ACESSO_FULL_SUCESSO = 'salva_acesso_full_success';
export const REMOVE_ACESSO_FULL_SUCESSO = 'remove_acesso_full_success';
export const BUSCA_ACESSO_FULL_EDICAO_SUCESSO = 'busca_acesso_full_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_FULL = 'lista_configuracao_ponto_full';
export const LISTA_CONFIGURACAO_PONTO_FULL_SUCESSO = 'lista_configuracao_ponto_full_sucesso';
export const ALTERACAO_CHECK_PONTO_ACESSO_FULL = 'alteracao_check_ponto_acesso_full';
export const LISTA_ACESSO_FULL_SUCESSO = 'lista_acesso_full_sucesso';
export const LIMPAR_ACESSO_FULL = 'limpar_acesso_full';
export const LISTA_ACESSO_FULL = 'lista_acesso_full';
export const LISTA_LOCAIS_POR_CLIENTE_ACESSO_FULL = 'lista_locais_por_cliente_acesso_full';
export const LISTA_LOCAIS_SUCESSO_ACESSO_FULL = 'lista_locais_sucesso_acesso_full';
export const CONSULTA_CPF_SUCESSO_FULL = 'consulta_cpf_sucesso_full';

// Concessão de Acessos (Acesso Bloqueado)
export const INICIA_ACESSO_BLOQUEIO = 'inicia_acesso_bloqueio';
export const SALVA_ACESSO_BLOQUEIO = 'salva_acesso_bloqueio';
export const REMOVE_ACESSO_BLOQUEIO = 'remove_acesso_bloqueio';
export const NOVO_ACESSO_BLOQUEIO = 'novo_acesso_bloqueio';
export const BUSCA_ACESSO_BLOQUEIO_EDICAO = 'busca_acesso_bloqueio_edicao';
export const INICIO_ACESSO_BLOQUEIO_SUCESSO = 'inicio_acesso_bloqueio_sucesso';
export const SALVA_ACESSO_BLOQUEIO_SUCESSO = 'salva_acesso_bloqueio_success';
export const REMOVE_ACESSO_BLOQUEIO_SUCESSO = 'remove_acesso_bloqueio_success';
export const BUSCA_ACESSO_BLOQUEIO_EDICAO_SUCESSO = 'busca_acesso_bloqueio_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_BLOQUEIO = 'lista_configuracao_ponto_bloqueio';
export const LISTA_CONFIGURACAO_PONTO_BLOQUEIO_SUCESSO = 'lista_configuracao_ponto_bloqueio_sucesso';
export const ALTERACAO_CHECK_PONTO_ACESSO_BLOQUEIO = 'alteracao_check_ponto_acesso_bloqueio';
export const LISTA_ACESSO_BLOQUEIO_SUCESSO = 'lista_acesso_bloqueio_sucesso';
export const LIMPAR_ACESSO_BLOQUEIO = 'limpar_acesso_bloqueio';
export const LISTA_ACESSO_BLOQUEIO = 'lista_acesso_bloqueio';
export const LISTA_LOCAIS_POR_CLIENTE_ACESSO_BLOQUEIO = 'lista_locais_por_cliente_acesso_bloqueio';
export const LISTA_LOCAIS_SUCESSO_ACESSO_BLOQUEIO = 'lista_locais_sucesso_acesso_bloqueio';
export const CONSULTA_CPF_SUCESSO_BLOQUEIO = 'consulta_cpf_sucesso_bloqueio';

// Concessão de Acessos (Perfis)
export const INICIA_ACESSO_PERFIL = 'inicia_acesso_perfil';
export const SALVA_ACESSO_PERFIL = 'salva_acesso_perfil';
export const REMOVE_ACESSO_PERFIL = 'remove_acesso_perfil';
export const NOVO_ACESSO_PERFIL = 'novo_acesso_perfil';
export const BUSCA_ACESSO_PERFIL_EDICAO = 'busca_acesso_perfil_edicao';
export const INICIO_ACESSO_PERFIL_SUCESSO = 'inicio_acesso_perfil_sucesso';
export const SALVA_ACESSO_PERFIL_SUCESSO = 'salva_acesso_perfil_success';
export const REMOVE_ACESSO_PERFIL_SUCESSO = 'remove_acesso_perfil_success';
export const BUSCA_ACESSO_PERFIL_EDICAO_SUCESSO = 'busca_acesso_perfil_edicao_sucesso';
export const LISTA_LOCAIS_PERFIS_POR_CLIENTE = 'lista_locais_perfis_por_cliente';
export const LISTA_LOCAIS_PERFIS_SUCESSO = 'lista_locais_perfis_sucesso';
export const LISTA_CONFIGURACAO_PONTO_PERFIL = 'lista_configuracao_ponto_perfil';
export const LISTA_CONFIGURACAO_PONTO_PERFIL_SUCESSO = 'lista_configuracao_ponto_perfil_sucesso';
export const LISTA_ACESSO_PERFIL_SUCESSO = 'lista_acesso_perfil_sucesso';
export const LIMPAR_ACESSO_PERFIL = 'limpar_acesso_perfil';
export const LISTA_ACESSO_PERFIL = 'lista_acesso_perfil';
export const ALTERACAO_CHECK_PONTO_ACESSO_PERFIL = 'alteracao_check_ponto_acesso_perfil'

// Concessão de Acessos (Genero)
export const INICIA_ACESSO_GENERO = 'inicia_acesso_genero';
export const SALVA_ACESSO_GENERO = 'salva_acesso_genero';
export const REMOVE_ACESSO_GENERO = 'remove_acesso_genero';
export const NOVO_ACESSO_GENERO = 'novo_acesso_genero';
export const BUSCA_ACESSO_GENERO_EDICAO = 'busca_acesso_genero_edicao';
export const INICIO_ACESSO_GENERO_SUCESSO = 'inicio_acesso_genero_sucesso';
export const SALVA_ACESSO_GENERO_SUCESSO = 'salva_acesso_genero_success';
export const REMOVE_ACESSO_GENERO_SUCESSO = 'remove_acesso_genero_success';
export const BUSCA_ACESSO_GENERO_EDICAO_SUCESSO = 'busca_acesso_genero_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_GENERO = 'lista_configuracao_ponto_genero';
export const LISTA_CONFIGURACAO_PONTO_GENERO_SUCESSO = 'lista_configuracao_ponto_genero_sucesso';
export const LISTA_ACESSO_GENERO_SUCESSO = 'lista_acesso_genero_sucesso';
export const LIMPAR_ACESSO_GENERO = 'limpar_acesso_genero';
export const LISTA_ACESSO_GENERO = 'lista_acesso_genero';
export const LISTA_LOCAIS_POR_CLIENTE_GENERO = 'lista_locais_por_cliente_genero';
export const ALTERACAO_CHECK_PONTO_ACESSO_GENERO = 'alteracao_check_ponto_acesso_genero';
export const ADICIONA_PERFIL_LISTA_VINCULADA_GENERO = 'adiciona_prefil_lista_vinculada_genero';
export const REMOVE_PERFIL_LISTA_VINCULADA_GENERO = 'remove_perfil_lista_vinculada_genero';   

// Concessão de Acessos (Idade)
export const INICIA_ACESSO_IDADE = 'inicia_acesso_idade';
export const SALVA_ACESSO_IDADE = 'salva_acesso_idade';
export const REMOVE_ACESSO_IDADE = 'remove_acesso_idade';
export const NOVO_ACESSO_IDADE = 'novo_acesso_idade';
export const BUSCA_ACESSO_IDADE_EDICAO = 'busca_acesso_idade_edicao';
export const INICIO_ACESSO_IDADE_SUCESSO = 'inicio_acesso_idade_sucesso';
export const SALVA_ACESSO_IDADE_SUCESSO = 'salva_acesso_idade_success';
export const REMOVE_ACESSO_IDADE_SUCESSO = 'remove_acesso_idade_success';
export const BUSCA_ACESSO_IDADE_EDICAO_SUCESSO = 'busca_acesso_idade_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_IDADE = 'lista_configuracao_ponto_idade';
export const LISTA_CONFIGURACAO_PONTO_IDADE_SUCESSO = 'lista_configuracao_ponto_idade_sucesso';
export const LISTA_ACESSO_IDADE_SUCESSO = 'lista_acesso_idade_sucesso';
export const LIMPAR_ACESSO_IDADE = 'limpar_acesso_idade';
export const LISTA_ACESSO_IDADE = 'lista_acesso_idade';
export const LISTA_LOCAIS_POR_CLIENTE_IDADE = 'lista_locais_por_cliente_idade';
export const ALTERACAO_CHECK_PONTO_ACESSO_IDADE = 'alteracao_check_ponto_acesso_idade';
export const ADICIONA_PERFIL_LISTA_VINCULADA_IDADE = 'adiciona_perfil_lista_vinculada_idade';
export const REMOVE_PERFIL_LISTA_VINCULADA_IDADE = 'remove_perfil_lista_vinculada_idade';

// Concessão de Acessos (Periodo)
export const INICIA_ACESSO_PERIODO = 'inicia_acesso_periodo';
export const SALVA_ACESSO_PERIODO = 'salva_acesso_periodo';
export const REMOVE_ACESSO_PERIODO = 'remove_acesso_periodo';
export const NOVO_ACESSO_PERIODO = 'novo_acesso_periodo';
export const BUSCA_ACESSO_PERIODO_EDICAO = 'busca_acesso_periodo_edicao';
export const INICIO_ACESSO_PERIODO_SUCESSO = 'inicio_acesso_periodo_sucesso';
export const SALVA_ACESSO_PERIODO_SUCESSO = 'salva_acesso_periodo_success';
export const REMOVE_ACESSO_PERIODO_SUCESSO = 'remove_acesso_periodo_success';
export const BUSCA_ACESSO_PERIODO_EDICAO_SUCESSO = 'busca_acesso_periodo_edicao_sucesso';
export const LISTA_CONFIGURACAO_PONTO_PERIODO = 'lista_configuracao_ponto_periodo';
export const LISTA_CONFIGURACAO_PONTO_PERIODO_SUCESSO = 'lista_configuracao_ponto_periodo_sucesso';
export const LISTA_ACESSO_PERIODO_SUCESSO = 'lista_acesso_periodo_sucesso';
export const LIMPAR_ACESSO_PERIODO = 'limpar_acesso_periodo';
export const LISTA_ACESSO_PERIODO = 'lista_acesso_periodo';
export const LISTA_LOCAIS_POR_CLIENTE_ACESSO_PERIODO = 'lista_locais_por_cliente_acesso_periodo';
export const ADICIONA_PERFIL_LISTA_VINCULADA_PERIODO = 'adiciona_prefil_lista_vinculada_periodo';
export const REMOVE_PERFIL_LISTA_VINCULADA_PERIODO = 'remove_perfil_lista_vinculada_periodo'; 

// Localidades
export const INICIA_LOCALIDADE = 'inicia_localidade';
export const SALVA_LOCALIDADE = 'salva_localidade';
export const REMOVE_LOCALIDADE = 'remove_localidade';
export const LISTA_LOCALIDADE = 'lista_localidade';
export const NOVO_LOCALIDADE = 'novo_localidade';
export const BUSCA_LOCALIDADE_EDICAO = 'busca_localidade_edicao';
export const INICIO_LOCALIDADE_SUCESSO = 'inicio_localidade_sucesso';
export const SALVA_LOCALIDADE_SUCESSO = 'salva_localidade_successo';
export const REMOVE_LOCALIDADE_SUCESSO = 'remove_localidade_successo';
export const BUSCA_LOCALIDADE_EDICAO_SUCESSO = 'busca_localidade_edicao_sucesso';
export const LISTA_LOCAIS_POR_CLIENTE_LOCALIDADE = 'lista_locais_por_cliente_localidade';
export const LISTA_LOCAIS_SUCESSO_LOCALIDADE = 'lista_locais_sucesso_localidade';
export const LISTA_LOCALIDADE_SUCESSO = 'lista_localidade_sucesso';
export const LIMPAR_LOCALIDADE = 'limpar_localidade';
export const ADICIONA_DEPARTAMENTO_LISTA_VINCULADA = 'adiciona_departamento_vinculada_periodo';
export const REMOVE_DEPARTAMENTO_LISTA_VINCULADA = 'remove_departamento_vinculada_periodo'; 
export const ADICIONA_PONTO_ACESSO_LISTA_VINCULADA_PERIODO= 'adiciona_ponto_acesso_lista_vinculada_periodo'; 
export const REMOVE_PONTO_ACESSO_LISTA_VINCULADA_PERIODO = 'remove_ponto_acesso_lista_vinculada_periodo';
export const LISTA_PONTO_ACESSO_SUCESSO_LOCALIDADE= 'lista_ponto_acesso_sucesso_localidade';
export const LISTA_PONTO_ACESSO_POR_LOCAL  = 'lista_ponto_acesso_por_local';     
export const ADICIONA_FUNCAO_LISTA_VINCULADA = 'adiciona_funcao_lista_vinculada';
export const REMOVE_FUNCAO_LISTA_VINCULADA = 'remove_funcao_lista_vinculada'; 
export const ADICIONA_GRUPO_LISTA_VINCULADA= 'adiciona_grupo_lista_vinculada'; 
export const REMOVE_GRUPO_LISTA_VINCULADA = 'remove_grupo_lista_vinculada';

// Vinculos Pessoa
export const INICIA_VINCULO_PESSOA = 'inicia_vinculo_pessoa';
export const SALVA_VINCULO_PESSOA = 'salva_vinculo_pessoa';
export const REMOVE_VINCULO_PESSOA = 'remove_vinculo_pessoa';
export const LISTA_VINCULO_PESSOA = 'lista_vinculo_pessoa';
export const NOVO_VINCULO_PESSOA = 'novo_vinculo_pessoa';
export const BUSCA_VINCULO_PESSOA_EDICAO = 'busca_vinculo_pessoa_edicao';
export const INICIO_VINCULO_PESSOA_SUCESSO = 'inicio_vinculo_pessoa_sucesso';
export const SALVA_VINCULO_PESSOA_SUCESSO = 'salva_vinculo_pessoa_successo';
export const REMOVE_VINCULO_PESSOA_SUCESSO = 'remove_vinculo_pessoa_successo';
export const BUSCA_VINCULO_PESSOA_EDICAO_SUCESSO = 'busca_vinculo_pessoa_edicao_sucesso';
export const LISTA_LOCAIS_POR_CLIENTE_VINCULO_PESSOA = 'lista_locais_por_cliente_vinculo_pessoa';
export const LISTA_LOCAIS_SUCESSO_VINCULO_PESSOA = 'lista_locais_sucesso_vinculo_pessoa';
export const LISTA_VINCULO_PESSOA_SUCESSO = 'lista_vinculo_pessoa_sucesso';
export const LIMPAR_VINCULO_PESSOA = 'limpar_vinculo_pessoa';
export const ADICIONA_VINCULO_PESSOA_LISTA_VINCULADA  = 'adiciona_vinculo_pessoa_lista_vinculada';   
export const REMOVE_VINCULO_PESSOA_LISTA_VINCULADA  = 'remove_vinculo_pessoa_lista_vinculada'; 
export const LISTA_LOCALIDADE_POR_LOCAL= 'lista_localidade_por_local';
export const LISTA_LOCALIDADE_POR_LOCAL_SUCESSO  = 'lista_localidade_por_local_sucesso';  
export const LISTA_DEPARTAMENTO_POR_LOCALIDADE= 'lista_departamento_por_localidade';
export const LISTA_DEPARTAMENTO_POR_LOCALIDADE_SUCESSO  = 'lista_departamento_por_localidade_sucesso';
export const CONSULTA_CPF_VINCULO  = 'consulta_cpf_vinculo';
export const CONSULTA_CPF_SUCESSO_VINCULO  = 'consulta_cpf_sucesso_vinculo';

// Gestão de acesso
export const CONSULTA_CPF_ACESSO  = 'consulta_cpf_acesso';
export const CONSULTA_CPF_ACESSO_SUCESSO  = 'consulta_cpf_acesso_sucesso';
export const OBTER_INFORMACAO_ACESSO_PONTO = 'obter_informacao_acesso_ponto';
export const REMOVE_PESSOA_LOCAL = 'remove_pessoa_local';
export const ADICIONA_PESSOA_LOCAL = 'adiciona_pessoa_local';
export const ADICIONA_PESSOA_LOCAL_SUCESSO = 'adiciona_pessoa_local_sucesso';
export const REMOVE_PERFIL_LOCAL = 'remove_perfil_local';
export const ADICIONA_PERFIL_LOCAL = 'adiciona_perfil_local';
export const ADICIONA_GENERO_LOCAL = 'adiciona_genero_local';
export const REMOVE_GENERO_LOCAL = 'remove_genero_local';
export const ADICIONA_IDADE_LOCAL = 'adiciona_idade_local';
export const REMOVE_IDADE_LOCAL = 'remove_idade_local';
export const CONSULTA_CPF_ACESSO_FULL = 'remove_cpf_acesso_full';
export const CONSULTA_CPF_ACESSO_FULL_SUCESSO = 'remove_cpf_acesso_full_sucesso';
export const ADICIONA_PESSOA_LOCAL_FULL_SUCESSO = 'adiciona_pessoa_local_full_sucesso';
export const ADICIONA_PESSOA_LOCAL_FULL = 'adiciona_acesso_full_local';
export const REMOVE_PESSOA_LOCAL_FULL = 'remove_pessoa_local_full';
export const OBTER_ACESSO_LOCAL_CONTROLADO = 'obter_acesso_local_controlado';
export const OBTER_ACESSO_LOCAL_CONTROLADO_SUCESSO = 'obter_acesso_local_controlado_sucesso';
export const ADICIONA_PESSOA_LOCAL_BLOQUEIO_SUCESSO= 'adiciona_pessoa_local_bloqueio_sucesso';
export const REMOVE_PESSOA_LOCAL_BLOQUEIO= 'remove_pessoa_local_bloqueio';
export const CONSULTA_CPF_ACESSO_BLOQUEIO = 'consulta_cpf_acesso_bloqueio';
export const CONSULTA_CPF_ACESSO_BLOQUEIO_SUCESSO = 'consulta_cpf_acesso_bloqueio_sucesso';
export const ADICIONA_PERIODO_LOCAL = 'adiciona_periodo_local';
export const REMOVE_PERIODO_LOCAL = 'remove_periodo_local';
export const REMOVE_HORARIO_PERIODO = "remove_horario_periodo";
export const EDITA_PERIODO_LOCAL =  "edita_periodo_local";
export const CANCELA_PERIODO_LOCAL = "cancela_periodo_local";
export const ALTERACAO_CHECK_TODOS_DIAS_SEMANA = 'alteracao_check_todos_dias_semana';
export const ALTERACAO_CHECK_EM_QUALQUER_HORARIO = 'alteracao_check_qualquer_horario';
export const ALTERACAO_CHECK_INICIO_FIM_PROGRAMADO = 'alteracao_check_inicio_fim_programado';
export const ADICIONA_TODA_BASE_SUCESSO = 'adiciona_toda_base_sucesso';
export const ADICIONA_TODA_BASE_LOCAL = 'adiciona_toda_base_local';
export const ADICIONA_PESSOA_LOCAL_BLOQUEIO = 'adiciona_pessoa_local_bloqueio';
export const ADICIONA_PERFIL_LOCAL_SUCESSO = 'adiciona_perfil_local_sucesso';
export const ADICIONA_GENERO_LOCAL_SUCESSO = 'adiciona_genero_local_sucesso';
export const ADICIONA_IDADE_LOCAL_SUCESSO = 'adiciona_idade_local_sucesso';
export const ADICIONA_PERIODO_LOCAL_SUCESSO = 'adiciona_periodo_local_sucesso';
export const CONSULTA_CPF_PERIODO = 'consulta_cpf_periodo';
export const CONSULTA_CPF_PERIODO_SUCESSO = 'consulta_cpf_periodo_sucesso';
export const NOVO_HORARIO = 'novo_horario';
export const REMOVE_HORARIO = 'remove_horario';
export const ATUALIZA_HORARIO = 'atualiza_horario';
export const ATUALIZA_AMBIENTE_PORTARIA = 'atualiza_ambiente_portaria';
export const ATUALIZA_CONTROLE_GARAGEM = 'atualiza_controle_garagem';
export const EXIBIR_ESTRUTURA_COPIA_ACESSOS = 'exibir_estrutura_copia_acessos';

// Portaria Inicio

export const INICIA_PESSOAS = 'inicia_pessoas';
export const INICIA_LIBERACOES = 'inicia_liberacoes';
export const ALTERACAO_CAMPO_PORTARIA = 'alteracao_campo_portaria';
export const FECHA_MODULOS_PORTARIA = 'fecha_modulos_portaria';
export const SELECIONA_MODULOS_PORTARIA = 'seleciona_modulos_portaria';
export const IR_PARA_MODULO = 'ir_para_modulo';
export const INICIA_PORTARIA_SUCESSO = 'inicia_portaria_sucesso';